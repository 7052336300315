import { useHeadroom, useDisclosure } from "@mantine/hooks";
import { Outlet } from "react-router-dom";
import { AppShell, rem } from "@mantine/core";
import Header from "./Header";
import { useUserQuery } from "../../queries/user";
import FullPageLoader from "./FullPageLoader";

export function MainLayout(props) {
  const pinned = useHeadroom({ fixedAt: 720 });
  const [opened, { toggle }] = useDisclosure();
  const { isLoading } = useUserQuery();

  if (isLoading) return <FullPageLoader />;
  return (
    <AppShell
      header={{ height: 60, offset: false }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { desktop: !opened, mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Header pinned={pinned} opened={opened} toggle={toggle} />
      </AppShell.Header>
      <AppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
