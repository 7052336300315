import React from "react";
import { Image, useMantineColorScheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export default function Logo() {
  const navidate = useNavigate();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Image
      src={
        colorScheme === "light"
          ? "/assets/images/cxq.svg"
          : "/assets/images/cxqwhite.svg"
      }
      h={50}
      onClick={() => {
        navidate("/");
      }}
      style={{ cursor: "pointer" }}
    />
  );
}
