import { Routes, Route, useLocation } from "react-router-dom";

import React from "react";
import { MainLayout } from "./components/common/MainLayout";
import Home from "./components/home";

export default function Router() {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route element={<MainLayout />}>
        <Route element={<Home />} path={"/"}></Route>
      </Route>
    </Routes>
  );
}
