import { Group, Button } from "@mantine/core";
import React from "react";
import Logo from "./Logo";

export default function Header({ pinned }) {
  return (
    <Group h="100%" px="md">
      <Group justify="space-between" style={{ flex: 1 }}>
        <Logo />
        {!pinned && (
          <Group ml="xl" gap={0} visibleFrom="sm">
            <Button
              size="lg"
              component={"a"}
              href="https://app.coursexquiz.com"
              variant="gradient"
              gradient={{ from: "blue", to: "cyan" }}
              style={{
                fontSize: "1.1rem",
                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
              }}
            >
              Get Started
            </Button>
          </Group>
        )}
      </Group>
    </Group>
  );
}
