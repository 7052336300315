import { Box } from "@mantine/core";
import React from "react";
import { About } from "./About";

export default function Home() {
  return (
    <Box>
      <About />
    </Box>
  );
}
