import {
  Image,
  Container,
  Title,
  Text,
  Group,
  Stack,
  Button,
  Box,
} from "@mantine/core";
import classes from "./Landing.module.css";

export function About() {
  return (
    <Container size="xl">
      <Box>
        <Group justify="space-between" className={classes.inner} align="center">
          <Stack spacing="xl" maw={500}>
            <Title size="h1" fw={900} c="blue.7" lh={1.2}>
              Learn a <span className={classes.highlight}>course</span> in a way
              that is both enjoyable and effective,
              <Text span c="dimmed" inherit>
                all at no cost.
              </Text>
            </Title>
            <Group mt={40}>
              <Button
                size="lg"
                fullWidth
                component={"a"}
                href="https://app.coursexquiz.com"
                variant="gradient"
                gradient={{ from: "blue", to: "cyan" }}
              >
                Get Started
              </Button>
            </Group>
          </Stack>
          <Image
            src={"/assets/images/il1.png"}
            h={500}
            style={{
              transform: "scale(1.1)",
              transition: "transform 300ms ease",
            }}
            className={classes.image}
          />
        </Group>
        <Group justify="space-between" align="center" className={classes.inner}>
          <Image
            src={"/assets/images/interative-learning.png"}
            h={400}
            style={{ transform: "scale(1.1)" }}
            className={classes.image}
          />
          <Stack spacing="xl">
            <Title size="h2" fw={700} c="blue.7">
              Interactive Learning Experience
            </Title>
            <Text size="lg" maw={450} lh={1.8}>
              Say goodbye to monotonous lectures and static textbooks. We
              transforms your learning into a fun and interactive experience.
              Dive into a world of quizzes that challenge, engage, and reinforce
              your understanding of key concepts.
            </Text>
          </Stack>
        </Group>

        <Group
          justify="space-between"
          align="center"
          className={classes.inner}
          mt={80}
        >
          <Image
            src={"/assets/images/personalized.png"}
            h={400}
            style={{ transform: "scale(1.1)" }}
            className={classes.image}
          />
          <Stack spacing="xl">
            <Title size="h2" fw={700} c="blue.7">
              Personalized Learning Paths
            </Title>
            <Text size="lg" maw={450} lh={1.8}>
              No two learners are the same. Our app tailors quizzes to your
              unique learning style, ensuring that you get the most out of every
              question. Say hello to a personalized learning journey designed
              just for you.
            </Text>
          </Stack>
        </Group>

        <Group justify="space-between" align="center" mt={80}>
          <Stack spacing="xl">
            <Title size="h2" fw={700} c="blue.7">
              Real-Time Feedback
            </Title>
            <Text size="lg" maw={450} lh={1.8}>
              Receive instant feedback on your quiz performance. Know what you
              got right, understand your mistakes, and track your progress over
              time. CourseXQuiz empowers you to learn from every attempt, making
              your study sessions more effective.
            </Text>
          </Stack>
          <Image
            src={"/assets/images/feedback.png"}
            h={400}
            style={{ transform: "scale(1.1)" }}
            className={classes.image}
          />
        </Group>

        <Box mt={100} py={60} bg="blue.0" style={{ borderRadius: "16px" }}>
          <Stack align="center" spacing="xl">
            <Title size="h2" fw={700} c="blue.7">
              Ready to Elevate Your Learning?
            </Title>
            <Text maw={700} ta="center" size="lg" lh={1.8}>
              Don't settle for passive learning. Embrace the power of active
              engagement with CourseXQuiz. Join thousands of learners who are
              already experiencing a transformative approach to education.
            </Text>
            <Button
              size="lg"
              w={300}
              component={"a"}
              href="https://app.coursexquiz.com"
              variant="gradient"
              gradient={{ from: "blue", to: "cyan" }}
              style={{
                marginTop: "20px",
                fontSize: "1.1rem",
                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
              }}
            >
              Get Started
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
